@import 'src/styles/imports';

.input-wrapper {
  position: relative;
  display: flex;
  box-sizing: border-box;
  width: 300px;
  border: 1px solid $color-grey-5;
  border-radius: var(--themes-buttons-border-radius);
  background-color: $color-white;

  &.is-commercial {
    border-radius: $border-radius-large;
  }

  &.is-commercial .drop-button {
    padding-right: .3em;
    padding-left: .7em;
  }

  @media (max-width: $breakpoint-tablet) {
    width: 100%;
  }

  .child {
    flex-grow: 1;
  }

  input {
    width: 100%;
    height: 100%;
    padding: 1em !important; // sass-lint:disable-line no-important
    border: 0 !important; // sass-lint:disable-line no-important

    &::placeholder {
      font-weight: $font-weight-semibold;
    }

    &:nth-child(2) {
      @media screen {
        width: 100%;
      }
    }

    &:first-child {
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
    }

    &:last-child {
      border-top-right-radius: var(--themes-buttons-border-radius);
      border-bottom-right-radius: var(--themes-buttons-border-radius);
    }
  }

  .drop-button {
    display: flex;
    height: 100%;
    padding-right: .3em;
    padding-left: 1.7em;
    cursor: pointer;
    align-items: center;
    justify-content: center;

    img,
    svg {
      padding: 0;
      margin: 0;
    }
  }

  .dropdown-content {
    position: absolute;
    z-index: $z-index-dropdown;
    display: flex;
    width: 4rem;
    padding: .5em;
    border: 1px solid $color-grey-5;
    border-radius: var(--themes-buttons-border-radius-medium);
    margin-top: .3em;
    background-color: $color-grey-1;
    align-items: center;
    flex-direction: column;
  }

  .drop-option {
    display: flex;
    width: 75%;
    border-radius: var(--themes-buttons-border-radius-medium);
    margin: .5em;
    cursor: pointer;
    justify-content: center;

    &:hover {
      box-shadow: 0 0 15px 0 var(--themes-colors-tertiary-background);
      background-color: var(--themes-colors-tertiary-background);
    }
  }
}

.clear-button {
  position: absolute;
  top: 50%;
  right: .8em;
  width: 1em;
  height: 1em;
  cursor: pointer;
  transform: translateY(-50%);
}

.input-wrapper.has-query .clear-button {
  display: block;
}
