@import 'src/styles/imports';

.component {
  font-size: .625em;
  position: absolute;
  z-index: $z-index-bump;
  top: 4em;
  left: 50%;
  display: none;
  box-shadow: $box-shadow;
  line-height: 1.4em;
  padding: 1em;
  border-radius: $border-radius-small;
  background-color: $color-grey-6;
  color: $color-white;
  white-space: nowrap;
  transform: translateX(-50%);

  &::after {
    @include nib(top, $color-grey-6, 4);
    left: 50%;
    transform: translateX(-50%);
  }
}

.active {
  display: block;
}
