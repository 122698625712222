@import 'src/styles/imports';

.suggestions-container {
  position: absolute;
  z-index: $z-index-dropdown;
  top: 50px;
  width: 350px;
  border: 1px solid $color-grey-1;
  border-radius: var(--themes-buttons-border-radius-medium);
  background-color: $color-white;

  @media (max-width: $breakpoint-tablet) {
    width: 100%;
  }
}

.suggestion {
  overflow-y: hidden;
  padding: 1em;
  margin: 1em;
  text-overflow: ellipsis;
  cursor: pointer;
  text-wrap: nowrap;

  &:hover {
    border-radius: var(--themes-buttons-border-radius-medium);
    background-color: $color-grey-1;
  }
}
