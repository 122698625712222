@import 'src/styles/imports';

.component {
  position: relative;
  display: flex;
  padding: .3em;
  border-radius: 50%;
  justify-content: center;

  &:hover {
    background-color: $color-black-10;
  }

  .icon {
    // Overwrites MUI production css props
    font-size: 1em !important; // sass-lint:disable-line no-important
    align-self: center;
  }

  .custom-tooltip-position {
    top: -.3em;
    left: 300%;
    line-height: 1em;
    border-radius: 25px;

    &::after {
      content: none; // removes nib
    }
  }
}
