@import 'src/styles/imports';

.component {
  width: 100%;
  min-height: calc(100vh - 100px);
  margin: 0 auto;
  margin-bottom: 2em;

  .result-count {
    font-size: 32px;
    padding: 1em;
    color: $color-black;
    font-weight: $font-weight-semibold;
    text-align: center;

    @media (max-width: $breakpoint-tablet) {
      font-size: 20px;
    }
  }

  .agent-header {
    position: relative;
    display: flex;
    height: 19em;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    .exp-header-banner {
      position: absolute;
      z-index: $z-index-0;
      top: -100px;
      left: 0;
      width: 100%;
      height: 24em;

      @media (max-width: $breakpoint-tablet) {
        top: -100px;
        left: 0;
        width: 100%;
        height: 26em;
      }
    }

    .agent-header-title {
      font-size: 40px;
      position: relative;
      z-index: $z-index-1;
      width: 13em;
      line-height: normal;
      margin-bottom: .5em;
      color: $color-black;
      font-weight: $font-weight-semibold;
      text-align: center;

      @media (max-width: $breakpoint-tablet) {
        font-size: 30px;
        padding-top: 4rem;
        font-weight: 300;
      }
    }

    .inputs {
      position: relative;
      z-index: $z-index-1;
      display: flex;
      padding: 1em;
      align-items: center;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;

      @media (max-width: $breakpoint-tablet) {
        padding-top: 1em;
      }
    }
  }

  .no-results {
    display: flex;
    text-align: center;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    h1 {
      font-size: 20px;
      padding: 1em;
      color: $color-black;
      font-weight: 500;

      @media (max-width: $breakpoint-tablet) {
        font-size: 16px;
        line-height: 24px;
      }
    }

    p {
      font-size: 16px;
      line-height: 24px;
      color: $color-grey-5;
      font-style: normal;
      font-weight: 400;

      @media (max-width: $breakpoint-tablet) {
        font-size: 14px;
        line-height: 24px;
      }
    }
  }

  .search-results-container {
    display: grid;
    padding: 0 3.3em 0 2.3em;
    grid-template-columns: minmax(0, 26 * 16 * 1em);
    grid-gap: .7em;

    @media (max-width: $breakpoint-tablet) {
      display: flex;
      padding: 0;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
    }

    @include tablet {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
    }

    @media (min-width: $breakpoint-large-desktop) {
      grid-template-columns: repeat(5, minmax(0, 26 * 16 * 1em));
      grid-gap: .5em;
    }

    @media (min-width: $breakpoint-min-4-columns) and (max-width: $breakpoint-large-desktop) {
      grid-template-columns: repeat(4, minmax(0, 26 * 16 * 1em));
      grid-gap: 1em;
    }

    @media (min-width: $breakpoint-min-3-columns) and (max-width: $breakpoint-min-4-columns) {
      grid-template-columns: repeat(3, minmax(0, 26 * 16 * 1em));
    }

    @media (max-width: $breakpoint-min-3-columns) {
      grid-template-columns: repeat(2, minmax(0, 26 * 16 * 1em));
    }
  }
}
