@import 'src/styles/imports';

.component {
  max-width: $max-width-app;
  min-height: 60vh;
  padding: 1em;
  margin: 0 auto;

  @include not-desktop {
    padding: 0;
  }

  .top-back-button {
    display: flex;
    margin: 2em 2em 0;
    font-weight: $font-weight-semibold;
    align-items: center;

    .back-arrow {
      margin-right: .5em;
    }
  }

  .content {
    @include flex-row;
    height: 100%;
    margin: 1em;

    @include large-mobile {
      @include flex-col;
    }

    &.is-commercial .main-info .agent-picture img {
      border-radius: 0;
    }

    &.is-commercial .bio-container .social-media .custom-button-style {
      border-radius: $border-radius-large;
    }

    .main-info {
      width: 30%;
      height: 100%;
      padding: 1em;
      @include flex-col;

      @media (max-width: 950px) {
        width: 40%;
      }

      @include large-mobile {
        width: 100%;
      }

      h1 {
        font-size: 2.5em;
        overflow-y: hidden;
        line-height: 1.2em;
        margin-bottom: .3em;
        font-weight: $font-weight-semibold;
        text-overflow: ellipsis;
        text-wrap: nowrap;
      }

      .agent-picture {
        position: relative; // for <NextImage> to fill
        width: 100%;
        margin-top: 2em;
        aspect-ratio: 1; // sass-lint:disable-line no-misspelled-properties

        img {
          border-radius: $border-radius-agent-image;
        }
      }
    }

    .bio-container {
      width: 65%;
      padding: 1em;

      @include not-desktop {
        width: 60%;
      }

      @include large-mobile {
        width: 100%;
      }

      .bio {
        @include flex-row;
        margin-bottom: 3em;
        justify-content: flex-start;

        @media (max-width: 950px) {
          @include flex-col;
        }

        .bio-heading {
          font-size: 24px;
          width: 200px;
          margin-right: 1em;
          margin-bottom: 1em;
          color: $color-dark-navy-80;
          font-weight: $font-weight-semibold;
        }

        .bio-content {
          width: 100%;
          color: $color-dark-navy-80;

          .personal-contact {
            @include flex-row;
            font-size: 1.1em;
            margin-bottom: 1em;
            align-items: center;
            justify-content: flex-start;

            p {
              margin-right: .5em;
              font-weight: $font-weight-semibold;
            }
          }

          .social-media {
            @include flex-row;
            flex-wrap: wrap;
            justify-content: flex-start;
            column-gap: 3px;
            row-gap: 15px;

            @include large-mobile {
              justify-content: space-between;
            }

            .custom-button-style {
              font-size: .9em;
              width: 140px;
              height: 3.2em;
              margin-right: 1em;

              @include large-mobile {
                width: 48%;
                margin-right: 0;
              }

              svg {
                font-size: .9em;
              }
            }

            .youtube-button {
              svg {
                path:nth-of-type(2) {
                  fill: $color-white;
                }
              }
              
              &:hover {
                svg {
                  path:nth-of-type(2) {
                    fill: var(--themes-colors-primary-font-color);
                  }
                }
              }
            }

            .twitter-button {
              &:hover {
                svg {
                  path {
                    fill: var(--themes-colors-primary-font-color);
                  }
                }
              }
            }
          }

          .bio-content-heading {
            p {
              font-size: 24px;
              margin-bottom: 1em;
              color: $color-dark-navy-80;
              font-weight: 700;
            }
          }

          .about {
            p, .about-inner-html { // sass-lint:disable-line single-line-per-selector
              font-size: .9em;
              line-height: 1.8em;
              font-weight: $font-weight-light;
            }
          }

          .learn-more {
            @include flex-row;
            padding: 1.5em;
            border-radius: $border-radius-large;
            background-image: url('/next/assets/images/learn-more.png');

            @media (max-width: $breakpoint-tablet) {
              @include flex-col;
            }

            .copy {
              h3 {
                font-size: 1.3em;
                margin-bottom: .5em;
                color: $color-black-75;
                font-weight: $font-weight-light;
              }

              p {
                font-size: .9em;
                line-height: 1.8em;
                font-weight: $font-weight-light;
              }
            }

            .cta {
              display: flex;
              margin-left: 3em;
              align-items: center;

              @media (max-width: $breakpoint-tablet) {
                margin-top: 1em;
                margin-left: 0;
              }
            }
          }

          .learn-more-no-bg {
            border: 1px solid $color-grey-2;
            background-image: none;
          }
        }
      }

      .bio-small-margin {
        margin-bottom: 1.5em;
      }
    }
  }
}
