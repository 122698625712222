@import 'src/styles/imports';

.input-wrapper {
  position: relative;
  display: flex;
  box-sizing: border-box;
  width: 300px;
  border: 1px solid $color-grey-5;
  border-radius: var(--themes-buttons-border-radius);
  margin-left: 1em;
  background-color: $color-white;

  &.is-commercial {
    border-radius: $border-radius-large;
  }

  @media (max-width: $breakpoint-tablet) {
    width: 100%;
    margin-top: 1em;
    margin-left: 0;
  }

  .child {
    flex-grow: 1;
  }

  input {
    width: 100%;
    height: 100%;
    padding: 1em !important; // sass-lint:disable-line no-important
    border: 0 !important; // sass-lint:disable-line no-important
    font-family: $font-family-sans-serif;

    &::placeholder {
      font-weight: $font-weight-semibold;
    }

    &:nth-child(2) {
      @media screen {
        width: 100%;
      }
    }

    &:first-child {
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
    }

    &:last-child {
      border-top-right-radius: var(--themes-buttons-border-radius);
      border-bottom-right-radius: var(--themes-buttons-border-radius);
    }
  }

  .search-button {
    display: flex;
    height: 100%;
    padding-right: .3em;
    padding-left: .7em;
    align-items: center;
    justify-content: center;
  }
}

.clear-button {
  position: absolute;
  top: 50%;
  right: .8em;
  width: 1em;
  height: 1em;
  cursor: pointer;
  transform: translateY(-50%);
}

.input-wrapper.has-query .clear-button {
  display: block;
}
