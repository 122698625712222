@import 'src/styles/imports';

.component {
  padding-top: 1.25em;
  padding-bottom: 1.75em;
  font-family: $font-family-sans-serif;
  @include flex-center;

  button {
    font-size: 16px;
    width: 55px;
    height: 55px;
    padding: 18px;
    border: 1px solid $color-grey-5;
    margin: 0 6px 0 0;
    font-family: $font-family-sans-serif;

    @include mobile {
      font-size: 14px;
      width: 40px;
      height: 40px;
      padding: 13px;
    }

    &.arrow:hover {
      border-color: $color-transparent;
      background-color: $color-transparent;

      svg path {
        fill: $color-grey-10;
      }
    }

    svg {
      height: .8em;
      margin: 0;
    }

    &.left {
      svg {
        transform: scale(-1);
      }
    }

    &.arrow:disabled {
      svg path {
        fill: $color-grey-4;
      }
    }
  }
}
