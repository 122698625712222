@import 'src/styles/imports';

.agent-card {
  position: relative;
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 100%;
  border-radius: $border-radius-agent-image;
  margin: .5em;
  cursor: pointer;
  flex-direction: column;

  &.is-commercial {
    border: 1px solid $color-grey-16;
    border-radius: $border-radius-medium;

    .agent-photo {
      border-radius: 0;
    }
  }

  &:hover {
    text-decoration: none;

    .agent-photo-wrapper {
      img {
        transform: scale(1.03);
      }
    }
  }

  .agent-photo {

    @include mobile {
      border-radius: $border-radius-agent-image-mobile;
    }
  }
}

.agent-info {
  display: flex;
  padding: 1em;
  flex-direction: column;
  flex: 1;

  .agent-name {
    font-size: 1.25rem;
    margin-bottom: .25rem;
    font-weight: $font-weight-semibold;
  }

  .agent-location {
    font-size: 1rem;
    padding-top: .5em;
    color: $color-grey-5;
  }

  .agent-bio {
    font-size: 1rem;
    display: -webkit-box;
    overflow: hidden;
    max-height: 3em;
    line-height: 1.3;
    margin-top: 1rem;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;

    span {
      padding-bottom: 0 !important; // sass-lint:disable-line no-important
    }
  }
}

.agent-photo-wrapper {
  position: relative;
  width: 100%;
  height: auto;
  min-height: 300px;
  border-radius: .5em .5em 0 0;
  object-fit: cover;
  flex-shrink: 0;
  aspect-ratio: 1; // sass-lint:disable-line no-misspelled-properties

  img {
    transition: all .3s ease;
  }
}

.agent-arrow {
  font-size: 2.5em;
  position: absolute;
  top: 300px;
  right: 10px;
  color: $color-grey-9;
}

@media (max-width: $breakpoint-tablet) {
  .agent-card {
    width: 100%;
    height: 150px;
    flex-direction: row;
  }

  .agent-photo-wrapper {
    width: 150px;
    height: 150px;
    min-height: 150px;
    border-radius: .5em 0 0 .5em;
  }

  .agent-info {
    .agent-bio {
      font-size: .8rem;
      max-height: 3em;
      margin-top: 1rem;
    }
  }

  .agent-arrow {
    top: 10px;
  }
}
